<template>
  <span class="">
    <v-menu offset-y :nudge-bottom="2">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="area.cor" class="text-nonecase fs-8pt pt-1 px-1 me-2" v-bind="attrs" v-on="on" outlined x-small>
          <i v-i:ic#preview#18 class="btn-icon-left pt-0"></i>
          <span>ver plano de ensino</span>
        </v-btn>
      </template>
      <v-card width="900" class="card-scroll" height="440px">
        <v-toolbar dense :color="hexTints(area.cor,0.97)">
          <v-toolbar-title class="ms-1 ps-0 fs-12pt f-lato fw-600">
            Plano de ensino
          </v-toolbar-title>
        </v-toolbar>
        <v-card class="p-2 m-2 mx-2" outlined elevation="3" :color="hexTints(area.cor,0.97)">
          <p class="m-0 p-0 fs-10pt fw-600 f-raleway">Ementa:</p>
          <div cols="12" class="mt-0 p-1 text-justify" v-if="qtdeConteudos == 0">
            <v-alert type="info" v-c:P :color="hexTints(area.cor,0.95)" class="m-0 p-2 mt-2 fs-10pt">
              Aguardando 1a. atualização
            </v-alert>
          </div>
          <p class="m-0 p-0 fs-10pt fw-300 f-raleway text-justify" v-if="qtdeConteudos > 0">
            <ementaviewitem :area="area" :conteudo="conteudo" :index="index" v-for="(conteudo,key,index) in conteudos" :key="key+refresh" />
          </p>
        </v-card>
        <v-card class="p-2 m-2 mx-2" outlined elevation="3" :color="hexTints(area.cor,0.97)">
          <p class="m-0 p-0 fs-10pt fw-600 f-raleway">Tópicos geradores:</p>
          <div cols="12" class="mt-0 p-1 text-justify" v-if="qtdeTopicos == 0">
            <v-alert type="info" v-c:P :color="hexTints(area.cor,0.95)" class="m-0 p-2 mt-2 fs-10pt">
              Aguardando 1a. atualização
            </v-alert>
          </div>
          <div class="m-0 p-0 fs-10pt fw-300 f-raleway" v-if="qtdeTopicos > 0">
            <v-container class="m-0 p-0 mt-1">
              <v-row dense>
                <v-col cols="6" class="m-0 py-0" v-for="(topico,key,index) in topicos" :key="key">
                  <v-card class="px-1 my-1" flat :color="hexTints(area.cor,0.98)">
                    <p class="m-0 p-0 line-height-4" v-if="topico != undefined" v-cHex="hexShades(area.cor,0.3)">
                      <span class="fs-8pt fw-600 f-lato">{{topico.id}}</span>
                      <span class="ms-2 fs-10pt fw-400 f-raleway">{{topico.texto}}</span>
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card>
        <v-card class="p-2 m-2 mx-2" outlined elevation="3" :color="hexTints(area.cor,0.97)">
          <p class="m-0 p-0 fs-10pt fw-600 f-raleway">Metas de compreensão:</p>
          <div cols="12" class="mt-0 p-1 text-justify" v-if="qtdeMetas == 0">
            <v-alert type="info" v-c:P :color="hexTints(area.cor,0.95)" class="m-0 p-2 mt-2 fs-10pt">
              Aguardando 1a. atualização
            </v-alert>
          </div>
          <div class="m-0 p-0 fs-10pt fw-300 f-raleway" v-if="qtdeMetas > 0">
            <v-container class="m-0 p-0 mt-1">
              <v-row dense>
                <v-col cols="12" class="m-0 py-0" v-for="(meta,key,index) in metas" :key="key">
                  <v-card class="px-1 my-1" flat :color="hexTints(area.cor,0.98)">
                    <p class="m-0 p-0 line-height-4" v-if="meta != undefined" v-cHex="hexShades(area.cor,0.3)">
                      <span v-if="meta.metaCodigo != ''" class="me-2 fs-8pt fw-600 f-lato">{{meta.metaCodigo}}</span>
                      <span class="fs-10pt fw-400 f-raleway">{{meta.texto}}</span>
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card>
        <v-card class="p-2 m-2 mx-2" outlined elevation="3" :color="hexTints(area.cor,0.97)">
          <p class="m-0 p-0 fs-10pt fw-600 f-raleway">Certificação:</p>
          <v-card class="px-1 my-1" flat :color="hexTints(area.cor,0.98)">
            <p class="m-0 p-0 line-height-4" v-cHex="hexShades(area.cor,0.3)">
              <span class="ms-1 fs-10pt fw-400 f-raleway">{{uc.certificacao.texto}}</span>
            </p>
          </v-card>
        </v-card>
      </v-card>
    </v-menu>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "verplanoensino",
  components: {
    'ementaviewitem': () => import('@/views/cursos/planosv2/ementa/ementa-view-item.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      conteudos: {},
      qtdeConteudos: 0,
      topicos: {},
      qtdeTopicos: 0,
      ordem: "",
      metas: {},
      qtdeMetas: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    console.log("this.uc.id",this.uc.id);
    rdb.ref('/curriculo/conteudos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.conteudos = {};
        self.qtdeConteudos = 0;
        self.ordem = "";
      } else {
        self.qtdeConteudos = linha.qtde;
        self.ordem = linha.ordem;
        self.conteudos = {};
        var vetOrdem = [];
        if(linha.ordem != undefined) {
          vetOrdem = linha.ordem.split(";");
        }
        for(var idx in vetOrdem) {
          self.conteudos[vetOrdem[idx]] = linha.conteudos[vetOrdem[idx]];
        }
      }
      self.refresh++;
      console.log("self.conteudos",self.conteudos);
    });

    rdb.ref('/curriculo/topicos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.topicos = {};
        self.qtdeTopicos = 0;
        self.ordem = "";
      } else {
        self.qtdeTopicos = linha.qtde;
        self.ordem = linha.ordem;
        self.topicos = {};
        var vetOrdem = [];
        if(linha.ordem != undefined) {
          vetOrdem = linha.ordem.split(";");
        }
        for(var idx in vetOrdem) {
          self.topicos[vetOrdem[idx]] = linha.topicos[vetOrdem[idx]];
        }
      }
      self.refresh++;
      console.log("self.topicos",self.topicos);
    });

    rdb.ref("/curriculo/metasNovas/").orderByChild("ucID").equalTo(self.uc.id).on('value',function(snapshot) {
      self.metas = {};
      self.qtdeMetas = 0;
      var metas = snapshot.val();
      if(metas != null) {
        self.metas = metas;
        self.qtdeMetas = Object.keys(metas).length;
      }
      console.log("self.metas",self.metas);
    });

  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
